import {cartTools} from "../cart/useCart";
import {t} from "../../shared-services/translate";

const productParser = (mode) => {
  const {getCurrentCart, lineItemFromProduct} = cartTools();

  const parsers = {
    catalogProductSingle: (product, currentUser = {}) => {
      const result = {...product};
      const cart = getCurrentCart();

      const lineItem = lineItemFromProduct(product, cart);

      if (lineItem) result.lineItem = lineItem;
      else delete result.lineItem;

      const {isFavorite, parsed} = extractFavorites(result, currentUser);

      return {...result, isFavorite, parsed};
    },
    catalogProducts: (products, currentUser = {}) => {
      const result = products
        .map(product => parsers.catalogProductSingle(product, currentUser));

      return result;
    },
    productPage: (product, currentUser = {}) => {
      if (!product) return product;

      const {ingredients, nutritionalInfo, additionalInfo, allergens} = parseProductInfo(product);

      const {isFavorite, parsed} = extractFavorites(product, currentUser);

      const variants = product?.variants
        ?.filter(variant => variant.quantity_in_units);

      return {...product, variants, ingredients, nutritionalInfo, additionalInfo, allergens, isFavorite, parsed};
    }
  };

  return ((mode) => parsers[mode || "catalogProducts"])(mode);
};

export default productParser;

const extractFavorites = (product, currentUser) => {
  const {favorites} = currentUser;

  const isFavorite = favorites ? favorites.map(fav => fav.id).includes(product.id) : false;
  const parsed = !!currentUser?.id;

  return {isFavorite, parsed};
};

const extractProperty = (property) => {
  return property
    ? {
      label: property?.property?.locale_presentation,
      value: property?.value
    }
    : null;
};

const parseProductInfo = (product) => {
  const propertyNames = {
    ingredients: "ingredient",
    nutritionalInfo: "nutrition",
    producer: "producer"
  };

  const properties = product?.product_properties;
  if (!properties) return {};

  const ingredientsProp = properties.find(property => property.property_name === propertyNames.ingredients);
  const ingredients = extractProperty(ingredientsProp);

  const nutritionalInfoProp = properties.find(property => property.property_name === propertyNames.nutritionalInfo);
  const nutritionalInfo = parseNutritionalInfo(nutritionalInfoProp);

  const producer = {
    label: t("product_page.producer"),
    value: product?.supplier?.name,
    url: product?.supplier?.seo_url
  };

  const allergens = product?.allergenic_data?.allergens || [];

  const additionalInfo = [
    producer,
    ...properties
      .filter(property => !Object.values(propertyNames).includes(property?.property_name))
      .map(property => extractProperty(property))
  ];

  return {ingredients, nutritionalInfo, additionalInfo, allergens};
};

const parseNutritionalInfo = (property) => {
  if (!property) return null;

  const extract = (string = "", regexPattern) => {
    const response = regexPattern.exec(string) || [];
    return response[0];
  };

  const parseElement = (string = "") => {
    const labelRegex = /[^ ][a-zA-Z\s][^ ]+/;
    const label = extract(string, labelRegex);

    const primaryValueRegex = /\d+(\.\d+)?\w+(?![^(]*\))/g;
    const primaryValue = extract(string, primaryValueRegex);

    const secondaryValueRegex = /\(.*\)/g;
    const secondaryValue = extract(string, secondaryValueRegex)?.replace(/\(|\)/g, "");

    return {label, primaryValue, secondaryValue};
  };

  const value = property?.value.split(",").map(str => parseElement(str));

  return extractProperty({...property, value});
};
window.parseNutritional = parseNutritionalInfo;
