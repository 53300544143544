import {trackPageView} from "../services/tracking-helper";
import globalState from "../shared-services/globalState";

angular.module("app").controller("SupportContactFormPageCtrl", ["$scope", function($scope) {
  window.SupportContactFormPageCtrl = $scope;
  const {getGlobalState} = globalState();
  const {setPageCode} = getGlobalState("router");

  function constructor() {
    trackPageView("contact");
    setPageCode("contact_form");
  }

  function destructor() {
    window.SupportContactFormPageCtrl = null;
  }

  $scope.$on("$destroy", destructor);

  constructor();
}]);
