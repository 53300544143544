import cartState from "./globalState/cartState";
import topLayerState from "./globalState/topLayerState";
import sessionState from "./globalState/sessionState";
import routerState from "./globalState/routerState";
import layoutState from "./globalState/layoutState";
import * as _ from "underscore";

export const useGlobalState = () => {
  const {initialize} = globalState();
  initialize();
};

const globalState = (initialize = false) => {
  const initializeGlobalState = () => {
    if (getGlobalState()) return;

    const state = {};
    state.cart = cartState();
    state.topLayer = topLayerState();
    state.session = sessionState();
    state.router = routerState();
    state.layout = layoutState();

    setGlobalState(null, state);
  };

  const setGlobalState = (name = null, newState, broadcast = true) => {
    window.farmyGlobalState = window.farmyGlobalState || {};
    const baseState = name ? window.farmyGlobalState[name] : window.farmyGlobalState;
    const updatedState = {...baseState, ...newState};

    if (_.isEqual(baseState, updatedState)) return;

    if (name) window.farmyGlobalState[name] = updatedState;
    else window.farmyGlobalState = updatedState;

    if (broadcast) window.dispatchEvent(new CustomEvent("globalStateUpdated", {detail: {name, state: updatedState}}));
  };

  if (initialize) initializeGlobalState();

  return {getGlobalState, setGlobalState, initializeGlobalState};
};

export const getGlobalState = (name = null) => {
  const farmyGlobalState = window.farmyGlobalState;

  if (!farmyGlobalState) return;
  return name ? farmyGlobalState[name] : farmyGlobalState;
};
window.getGlobalState = getGlobalState;

export default globalState;
