import {globalModalQueue} from "../../../shared-services/globalState/topLayerState";
import globalState from "../../../shared-services/globalState";
const displayInterval = 6;

const useAlsoBoughtModal = () => {
  const {getGlobalState} = globalState();
  const {pushToQueue} = globalModalQueue();

  const sendCartAddition = (productID) => {
    const modalCounter = parseInt(sessionStorage.getItem("also_bought_modal_counter") || 0);
    const modalShown = sessionStorage.getItem("also_bought_modal_shown") === "true";
    const {isProductPage} = getGlobalState("router");
    const {currentZipcode} = getGlobalState("session");
    const nextCount = modalCounter + 1

    if (!modalShown && isProductPage && currentZipcode) {
      pushToQueue("AlsoBoughtModal", {productID});
    }

    if (nextCount % displayInterval === 0) {
      sessionStorage.removeItem("also_bought_modal_shown");
    }

    sessionStorage.setItem("also_bought_modal_counter", `${nextCount}`);
  };

  return {
    sendCartAddition
  };
};

export default useAlsoBoughtModal;
