import React from "react";
const FarmyExtrapolate = (props) => {
  const {children, extrapolations} = props;

  function replaceValues(str, extrapolations) {
    return str.replace(/\{(.*?)\}/g, (match, key) => {
      return key in extrapolations ? extrapolations[key] : match;
    });
  }

  const result = replaceValues(children, extrapolations);

  return <div style={{margin: 0, padding: 0}} dangerouslySetInnerHTML={{__html: result}}></div>;
};

export default FarmyExtrapolate;
