import * as _ from "underscore";

"use strict";

/**
 * Listens to shipping zipcode changes and runs cart contents validations related
 * to the shipping destination (Hubs compatibility, etc.)
 *
 * A visual element of the shipping stage is linked to scope variables
 * modified by this method.
 */
angular.module("app")
  .directive("checkoutDeliveryTimeHolidayMessage", function() {
    return {
      controller: ["$rootScope", "$scope", "$http", "$q", "$timeout", "Alerts", "blockUI", "$attrs", "$element", "$translate", "CartData", function($rootScope, $scope, $http, $q, $timeout, Alerts, blockUI, $attrs, $element, $translate, CartData) {
        window.checkoutDeliveryTimeHolidayMessage = $scope;
        $scope.CheckoutDeliveryTimeCtrl = window.CheckoutDeliveryTimeCtrl;

        function constructor() {
          if (canDisplayHolidayMessage()) {
            loadHolidaySpecialProducts().then(products => {
              const productIds = _.map(products, p => p.id);
              const holidayItems = _.filter(CartData.cart.line_items, li => _.indexOf(productIds, li.product_id) > -1);

              if (holidayItems.length > 0) {
                $scope.shouldShowUp = true;
                $scope.becauseOfItems = holidayItems;
              }
            });
          }
        }

        function destructor() {
          window.checkoutDeliveryTimeHolidayMessage = null;
        }

        function loadHolidaySpecialProducts() {
          return $q((resolve, reject) => {
            $http.get(`/api/products.json?named_set=all_holidays&per_page=100&template=product_in_catalog&locale=${$translate.use()}`).then(response => {
              holidaySpecialProducts = response.data.products;
              resolve(holidaySpecialProducts);
            });
          });
        }

        function canDisplayHolidayMessage() {
          const currentDate = new Date();
          const currentYear = currentDate.getFullYear();
          const periodStart = new Date(currentYear, 11, 19);
          const periodEnd = new Date(currentYear, 0, 1);
          return currentDate >= periodStart && currentDate <= periodEnd;
        }

        //
        // Public members
        //

        // Scrolls to the first row of slots that display no delivery issues.
        $scope.scrollToPresaleDeliveryTime = function() {
          const firstNonIssueSlotIndex = $scope.CheckoutDeliveryTimeCtrl.availableDeliverySlots
            .indexOf(_($scope.CheckoutDeliveryTimeCtrl.availableDeliverySlots).find((ds) => !ds.issues));

          if (firstNonIssueSlotIndex > -1) {
            const selector = $(".data-selector");
            const firstNonIssueSlot = $($(".delivery-slot")[firstNonIssueSlotIndex]);

            if (firstNonIssueSlot.offset()) { selector[0].scrollLeft = firstNonIssueSlot.offset().left - selector.innerWidth() / 4 }
          }
        };

        // Initialize
        let holidaySpecialProducts;

        $scope.shouldShowUp = false;

        $scope.$on("$destroy", destructor);
        constructor();
      }],
      scope: {

      },
      link: function($scope, $element, attributes) {

      },
      template: `<div ng-if="shouldShowUp">
            <div class="well well-white">            
              {{ 'checkouts.holiday_presale_slots_message' | translate }}
            </div>
        </div>`
    };
  });
