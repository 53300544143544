import * as _ from 'underscore';
import {trackEvent} from "../../services/tracking-helper";

angular.module('app').controller('SearchFormCtrl', ['$rootScope','$scope', '$q', '$http', '$element', '$timeout', '$interval', 'SearchHelper', 'CatalogServiceHelper', 'ProductNavigation', '$location', '$translate',
  function($rootScope, $scope, $q, $http, $element, $timeout, $interval, SearchHelper, CatalogServiceHelper, ProductNavigation, $location, $translate) {
    window.SearchFormCtrl = $scope; // required for inter-controller communication

    let minChars = 3;

    let loadSuggestionsTimeout = null;

    // formats date as 2015-10-17
    let httpCacheKey = new Date().toISOString().slice(0, 10);

    let maxContainerHeight = parseInt($('.search-form-suggestions-panel').css('height'));

    let isLoadingSearchData = false;

    let submitedSearch = false;

    $scope.isOpen = false;
    $scope.isCheckout = $location.path().indexOf('checkout') > -1;
    $scope.SearchHelper = SearchHelper;
    $scope.ProductNavigation = ProductNavigation;
    $scope.perPage = 4; // number of products to show in the product section of search results
    $scope.CatalogServiceHelper = CatalogServiceHelper;
    $scope.searchOrigin = null;

    $scope.currentStorefrontSupplier = window.currentStorefrontSupplier;

    $scope.submitSearch = function(event) {
      if ($scope.keywords && $scope.keywords.length > 0) {
        $scope.openSearchPage();
      }

      if (event)
        event.preventDefault();

      submitedSearch = true;
      $rootScope.$broadcast('search:dropdown:close');
    };

    $scope.suggest = function() {
      clearSuggestions();

      if ($scope.keywords && $scope.keywords.length >= minChars) {
        loadSuppliers().then(() => loadSuggestions($scope.keywords));
      } else {
        $scope.close();
      }
    };

    $scope.onBlur = function() {
      $scope.inputFocused = SearchHelper.inputFocused = false;
    };

    $scope.onFocus = function(event) {
      loadSuppliers();
      $scope.inputFocused = SearchHelper.inputFocused = true;

      if (submitedSearch) {
        event.target.value = '';
        submitedSearch = false;
      }

      if (!$scope.isOpen)
        return $scope.suggest();
    };

    $scope.open = function() {
      $scope.isOpen = SearchHelper.isOpen = true;
    };

    $scope.close = function({broadcast = false} = {}) {
      $scope.isOpen = SearchHelper.isOpen = false;

      if (broadcast) {
        $rootScope.$broadcast(
          "search:update",
          {
            productSuggestions: $scope.product_suggestions,
            taxonSuggestions: $scope.taxon_suggestions,
            keywordSuggestions: $scope.keyword_suggestions,
            supplierSuggestions: $scope.supplier_suggestions,
            keywords: $scope.keywords,
            isOpen: $scope.isOpen,
            origin: $scope.searchOrigin
          });
      }
    };

    $scope.dismissSearch = function(e) {
      if (e) {
        e.preventDefault();
        e.stopPropagation();
      }

      $scope.keywords = null;

      $scope.close({broadcast: true});
    };

    $scope.dismissSearchMobile = function(e, newLocation, keywords) {
      if (e) {
        e.preventDefault();
        e.stopPropagation();
      }

      $scope.keywords = null;

      if (newLocation && keywords)
        $location.path(newLocation).search({keywords: keywords});

      if (window.farmyMobileSidebar)
        window.farmyMobileSidebar.hideSidebarAndCloseSearch();

      $scope.close({broadcast: true});
    };

    $scope.dismissSearchAndSidebar = function(e) {
      if (e) {
        e.preventDefault();
        e.stopPropagation();
      }

      if (window.farmyMobileSidebar)
        window.farmyMobileSidebar.hideSidebarAndCloseSearch();

      $scope.close({broadcast: true});
    };

    $scope.anySuggestion = function() {
      return _.any($scope.supplier_suggestions) ||
        _.any($scope.taxon_suggestions) ||
        _.any($scope.product_suggestions) ||
        _.any($scope.certificate_suggestions) ||
        _.any($scope.keyword_suggestions);
    };

    $scope.openSearchPage = function(event, hideSidebar) {
      // $location.path(`/products?keywords=${$scope.keywords}&locale=${$translate.use()}`)
      trackEvent("search",
        {
          gtmObject: {
            type: 'direct',
            searchterm: $scope.keywords,
            selection: ''
          }
        }
      );

      const searchEngine = $location.search().search_engine;
      const debugSearch = $location.search().debug_search;

      const urlParams = {
        keywords: $scope.keywords,
        locale: $translate.use()
      };

      if (searchEngine) urlParams.search_engine = searchEngine;
      if (debugSearch) urlParams.debug_search = debugSearch;

      $location.path('products').search(urlParams);

      if (event) {
        event.preventDefault();
      }

      if (hideSidebar && window.farmyMobileSidebar) {
        window.farmyMobileSidebar.hideSidebarAndCloseSearch();
      }

      submitedSearch = true;
    };

    // private

    function clearSuggestions({broadcast = false} = {}) {
      $scope.supplier_suggestions = null;
      $scope.taxon_suggestions = null;
      $scope.product_suggestions = null;
      $scope.certificate_suggestions = null;
      $scope.keyword_suggestions = null;

      if (broadcast) {
        $rootScope.$broadcast(
          "search:update",
          {
            productSuggestions: $scope.product_suggestions,
            taxonSuggestions: $scope.taxon_suggestions,
            keywordSuggestions: $scope.keyword_suggestions,
            supplierSuggestions: $scope.supplier_suggestions,
            keywords: $scope.keywords,
            isOpen: $scope.isOpen,
            origin: $scope.searchOrigin
          }
        );
      }
    }

    function loadSuggestionsPerform(keywords) {
      const promises = [];
      promises.push(searchSuppliers(keywords));
      promises.push(searchProducts(keywords));
      promises.push(searchSuggestions(keywords));

      Promise.all(promises).then((values) => {
        $rootScope.$broadcast("search:update", {
          productSuggestions: $scope.product_suggestions,
          taxonSuggestions: $scope.taxon_suggestions,
          supplierSuggestions: $scope.supplier_suggestions,
          keywordSuggestions: $scope.keyword_suggestions,
          keywords: $scope.keywords,
          isOpen: $scope.isOpen,
          origin: $scope.searchOrigin
        });
      });
    }

    function loadSuggestions(keywords) {
      if (loadSuggestionsTimeout) {
        clearTimeout(loadSuggestionsTimeout);
      }

      loadSuggestionsTimeout = setTimeout(function() {
        loadSuggestionsPerform(keywords);
      }, 500);
    }

    /**
     * Returns a promise that always resolves only after suppliers are loaded or already exists.
     * Won't try to load it a second time if it's already loaded, will simply resolve.
     *
     * @returns {Promise}
     */
    function loadSuppliers() {
      return $q(function(resolve, reject) {
        if (!$scope.suppliers || $scope.suppliers.length === 0) {
          if (!isLoadingSearchData) {
            isLoadingSearchData = true;

            $http.get(apiUrl('/suppliers.json?per_page=5000')).then(function(response) {
              if (response.data.suppliers) $scope.suppliers = response.data.suppliers;
            }).catch(e => {
              $scope.suppliers = [];
            });
          }

          const promiseTimer = $interval(function() {
            if ($scope.suppliers) {
              resolve();
              isLoadingSearchData = false;
              $interval.cancel(promiseTimer);
            }
          }, 30);
        } else {
          resolve();
        }
      });
    }

    function searchProducts(keywords) {
      const searchEngine = $location.search().search_engine;
      const debugSearch = $location.search().debug_search;

      let url = apiUrl('/products/autosuggest.json');

      let params = {
        template: 'product_in_catalog',
        cachekey: httpCacheKey,
        keywords: keywords,
        hub_ids: [Hubs.currentHub.id],
        per_page: $scope.perPage,
        express_delivery: CartData.isExpressDelivery ? 't' : 'f'
      };

      if (searchEngine) params.search_engine = searchEngine;
      if (debugSearch) params.debug_search = debugSearch;

      const enableCache = searchEngine !== 'algolia';

      return new Promise((resolve, reject) => {
        $http.get(url, {enableCache, params}).then(function(response) {
          if (response.data.products) {
            $scope.product_suggestions = response.data.products;

            if (response.data.taxons) {
              $scope.taxon_suggestions = response.data.taxons.slice(0, 3);
            }
            onSuggestionsAdd();
          } else {
            $scope.product_suggestions = [];
          }

          resolve();
        });
      });
    }

    function searchSuggestions(keywords) {
      let url = "/suggestions?cachekey=" + httpCacheKey + "&term=" + keywords + "&min_items_found=2";

      Tracking.sendSearchEvent('query', keywords);

      return new Promise(resolve => {
        $http.get(url, {cache: true}).then(function(response) {
          if (response.data) {
            $scope.keyword_suggestions = response.data;
            onSuggestionsAdd();
          } else {
            $scope.keyword_suggestions = [];
          }

          resolve();
        });
      });
    }

    function searchSuppliers(keywords) {
      return new Promise(resolve => {
        if ($scope.suppliers) {
          $scope.supplier_suggestions = _.filter($scope.suppliers, function(supplier) {
            return supplier?.name?.toLowerCase().indexOf(keywords?.toLowerCase()) != -1;
          }).slice(0, 3);
          onSuggestionsAdd();
        }

        resolve();
      });
    }

    function onSuggestionsAdd() {
      if ($scope.inputFocused) {
        $scope.isOpen = SearchHelper.isOpen = $scope.anySuggestion();
      }
    }

    $rootScope.$on("search:close", () => {
      $scope.isOpen = SearchHelper.isOpen = false;
      $scope.keywords = null;
      clearSuggestions({broadcast: true});
    });

    $scope.$on("$destroy", function () {
      window.SearchFormCtrl = null;
    });

    $timeout(function() {
      maxContainerHeight = parseInt($(".search-form-suggestions-panel").css("height"));
    }, 500);

    // initialize
    $scope.initialize = function (origin) {
      $scope.searchOrigin = origin;
    };
  }]);
