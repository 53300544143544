import globalState from "../globalState";
import * as _ from "underscore";

const layoutState = () => {
  const {getGlobalState, setGlobalState} = globalState();
  const stateName = "layout";

  const globalLayout = getGlobalState(stateName);
  let layoutState = {
    mainContainerBoundaries: {topPosition: 0, bottomPosition: 0},
    rightSidebarLayout: {height: 250},
    ...globalLayout
  };

  const setLayoutState = (newState) => {
    const currentState = getGlobalState(stateName);
    const candidateNewState = {...currentState, ...newState};

    if (!_.isEqual(candidateNewState, currentState)) {
      layoutState = {...candidateNewState};
      setGlobalState(stateName, layoutState);
    }
  };

  const setMainContainerBoundaries = (mainContainerBoundaries) => setLayoutState({mainContainerBoundaries});
  const setRightSidebarLayout = (rightSidebarLayout) => setLayoutState({rightSidebarLayout});

  return {
    ...layoutState,
    setMainContainerBoundaries,
    setRightSidebarLayout
  };
};
export default layoutState;
