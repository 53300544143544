import React from "react";
import {RjsSupportContactFormProductOptions} from "./RjsSupportContactFormProductOptions";

export class RjsSupportContactFormOrderSelector extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentUser: UserService.currentUser
    };
    this.$http = props.http;
    this.firstLoad = true;

    this.selectTypeError = this.selectTypeError.bind(this);
  }

  render(props) {
    if (this.state.currentUser?.id) {
      return (<div>
        <div className='support-form-order-selector'>
          <h2 className='align-center'>{$translate.instant("contact.select_order")}</h2>
          <i className={"fa fa-caret-down" + this.state.lastOrders ? "" : "hidden"}/>
          {this.buildOrderList()}
        </div>
        <div className='support-form-product-selector'>
          {this.buildProductList()}
        </div>
      </div>);
    } else {
      return null;
    }
  }

  componentDidMount() {
    if (this.state.currentUser?.id) {
      this.$http.get("/api/frontend/support_contact/last_orders.json").then((response) => {
        this.setState({lastOrders: response.data}, () => {
          this.props.onChildComponentLoaded(true);
        });
      }, (error) => {
        console.debug(error);
      });
    } else {
      this.props.onChildComponentLoaded(true);
    }
  }

  buildOrderList() {
    if (this.state.lastOrders == null) return;

    const builtOrders = this.state.lastOrders.map((order, index) => {
      return <option key={index} value={index}>{order.number} - {order.formated_delivery_time} - CHF {order.total}</option>;
    });

    return <select name="selectedOrder" className='order-selector' onChange={this.selectOrder.bind(this)} defaultValue='default_value'>
      <option disabled={true} value='default_value'>{$translate.instant("contact.select_order")}</option>
      {builtOrders}</select>;
  }

  buildProductList() {
    if (this.state.lastOrders == null) return;
    if (this.props.customData.selectedOrder == null) return;

    return this.props.customData.selectedOrder.products.map((product, index) => {
      return <div className='support-form-product-checkbox-content'>
        <label key={product.id} className='support-form-product-checkbox'>
          <p className='text'>{product.name} - CHF {product.price}</p>
          <input type="checkbox" className='hidden' value={index} onChange={this.selectProducts.bind(this)}/>
          <i className='fa fa-ok-circled check-icon' />
          <i className='check-icon-placeholder' />
        </label>
        <RjsSupportContactFormProductOptions product={product} onSelectTypeError={this.selectTypeError}/>
      </div>;
    });
  }

  selectOrder(e) {
    this.props.customData.selectedOrder = this.state.lastOrders[e.target.value];
    this.props.onCustomDataChanges(this.props.customData);
  }

  selectProducts(e) {
    this.props.customData.selectedOrder.products[e.target.value].checked = !this.props.customData.selectedOrder.products[e.target.value].checked;
    this.props.onCustomDataChanges(this.props.customData);
  }

  selectTypeError(product, typeError) {
    const index = _.findIndex(this.props.customData.selectedOrder.products, (each_p) => { return each_p.id === product.id });
    if (index >= 0 && typeError) {
      this.props.customData.selectedOrder.products[index].type_error = typeError.key;
      this.props.onCustomDataChanges(this.props.customData);
    }
  }
}
