import * as _ from "underscore";
import PaginationConfig from "../../react-components/tools/PaginationConfig";
import globalState from "../../shared-services/globalState";

angular.module("app").controller("CatalogSearchViewCtrl", ["$scope", "$rootScope", "$q", "$routeParams", "$location", "$window", "$http", "$element", "$timeout", "$compile", "Alerts", "CatalogServiceHelper", "ProductNavigation", "$uibModal", "Hubs", "$translate", "$sce", "$localStorage", "UiStateService",
  function($scope, $rootScope, $q, $routeParams, $location, $window, $http, $element, $timeout, $compile, Alerts, CatalogServiceHelper, ProductNavigation, $uibModal, Hubs, $translate, $sce, $localStorage, UiStateService) {
    window.CatalogSearchViewCtrl = $scope;
    const {getGlobalState} = globalState();
    const {setPageCode} = getGlobalState("router");

    let _destroyed = false;
    let unbindCatalogFiltersChanged;

    function constructor() {
      $scope.isMobile = window.isMobile;
      $scope.isSearchController = true;
      $scope.sortMode = $location.search().sort_mode || "relevancy"; // default sort mode or from parameter
      $scope.keywords = $location.search().keywords || null; // empty keywords or from parameter;
      setPageCode("search_results");
      if ($scope.keywords.replaceAll) {
        $scope.keywords = $scope.keywords.replaceAll(/\[|\]|\"|\{|\}|\)|\(|\;/g, "");
      }

      $scope.listMode = CatalogServiceHelper.lastUsedListMode = window.isMobile ? "mobile_cards" : ($localStorage.categorylistModeLayout || "full");

      // Special parameters for debug-listing of recommendations based on the current order
      $scope.recommendQuery = $location.search().recommend || null;
      $scope.recommendOrderId = $location.search().recommend_order_id || null;

      CatalogServiceHelper.isSearchView = true;
      CatalogServiceHelper.setCurrentTaxon(null);

      $timeout(() => {
        $scope.update();
      });

      $rootScope.$on("hubs:changed", function(event, options) {
        if (!_destroyed) {
          if ($scope.products) $scope.products.length == 0;
          $scope.update();
        }
      });

      unbindCatalogFiltersChanged = $rootScope.$on("catalog:filters:changed", function(event, options) {
        if (!_destroyed) {
          if ($scope.products) $scope.products.length == 0;
          $scope.update();
        }
      });

      $rootScope.$on("catalog:filters:master:changed", function(event, options) {
        if (!_destroyed) { $scope.update({resetProducts: true, resetPage: true}) }
      });

      // $rootScope.$on('$translateChangeSuccess', function () {
      //   $scope.update();
      // }); // updated via global farmyNgView listener
    }

    function destructor() {
      if (_destroyed) { return }

      CatalogServiceHelper.isSearchView = false;
      window.CatalogSearchViewCtrl = null;

      if ($scope.currentLoadProductsRequest && $scope.currentLoadProductsRequest.resolve) {
        $scope.currentLoadProductsRequest.resolve();
      }

      if (window.farmyWebsiteHeader) window.farmyWebsiteHeader.keywords = "";

      if ($scope.paginator) {
        $scope.paginator.destroy();
        $scope.paginator = null;
      }

      if (unbindCatalogFiltersChanged) unbindCatalogFiltersChanged();

      _destroyed = true; // This flag will be checked by potential hanging listeners that continue to work after destroy is called
    }

    // Public
    $scope.CatalogServiceHelper = CatalogServiceHelper;
    $scope.UiStateService = UiStateService;
    $scope.$translate = $translate;
    $scope.$sce = $sce;
    $scope.$location = $location;

    /**
     * Pagination info, reference must be presevered for paginator to work.
     *
     * @type {{}}
     */
    $scope.pagination = {
      page: $location.search().page || 1,
      per_page: $location.search().per_page || PaginationConfig.itemsPerPage
    };

    $scope.paginator = new ProductPaginator($scope, $scope.pagination);

    /**
     * Loads a product collection based on selected filters
     */
    $scope.loadProducts = function() {
      return $q((resolve, reject) => {
        const params = {template: "product_in_catalog"};
        params.filters = "t";

        if ($scope.keywords != null || $scope.keywords != "") {
          params.keywords = $scope.keywords;
        }

        if ($scope.recommendQuery != null || $scope.recommendQuery != "") {
          params.recommend = $scope.recommendQuery;
          params.recommend_order_id = $scope.recommendOrderId;
        }

        if ($location.search().explain || window.explainProductList) params.explain = "t";

        params.express_delivery = CartData && CartData.isExpressDelivery ? "t" : "";

        params.page = $scope.pagination.page || 1;
        params.per_page = $scope.pagination.per_page || 16;
        params.sort_mode = $scope.sortMode;
        params.locale = $translate.use();

        if ($location.search().search_engine) params.search_engine = $location.search().search_engine;

        if ($location.search().debug_search) params.debug_search = $location.search().debug_search;

        if (window.CatalogFilterCtrl) {
          const filters = window.CatalogFilterCtrl.getSelection();

          params.s = filters;
          params.s.hub_ids = Hubs.currentHubId;
        }

        CatalogServiceHelper.isLoading = $scope.isLoading = true;

        $scope.currentLoadProductsRequest = $q.defer();

        $http.get(`/api/products.json?${$.param(params)}`, {timeout: $scope.currentLoadProductsRequest.promise}).then((result) => {
          if (_destroyed || $scope.paginator == null) {
            reject(false);
            return;
          }

          const robotTagValue = "NOINDEX,FOLLOW";
          $rootScope.setRobotsMeta(robotTagValue);
          
          if (UserService.isAdmin && result.data.explain_url) {
            window.open(result.data.explain_url);
          }

          $scope.paginator.updatePaginationFromResponse(result.data);

          if ($scope.pagination.current_page == 1 || $scope.products == null || $scope.products.length == 0) {
            $scope.products = result.data.products;
          } else { // Non-first page will merge results
            _.each(result.data.products, p => _.findIndex($scope.products, ep => ep.id == p.id) == -1 && $scope.products.push(p));
          }

          // Inject and update filters into the neighboring filters controller, if present
          if (result.data.filters) {
            if (window.CatalogFilterCtrl) {
              $timeout(() => {
                if (window.CatalogFilterCtrl) {
                  window.CatalogFilterCtrl.updateFilters(result.data.filters);
                  $rootScope.$broadcast("catalog:filters:loaded", {filters: result.data.filters, sender: $scope, viewmode: "search"});
                  CatalogServiceHelper.onFilterSelectionChange();
                }
                
                CatalogServiceHelper.isLoading = $scope.isLoading = false;
              });
            } else {
              $scope.filters = result.data.filters;

              // window.CatalogFilterCtrl.updateFilters(result.data.filters);
              $rootScope.$broadcast("catalog:filters:loaded", {filters: result.data.filters, sender: $scope, viewmode: "search"});

              CatalogServiceHelper.isLoading = $scope.isLoading = false;
              CatalogServiceHelper.onFilterSelectionChange();
            }
          }

          resolve($scope.products);
        });
      });
    };

    $scope.update = function(options) {
      if (options && options.resetPage) { $scope.pagination.page = $scope.pagination.current_page = 1 }

      if (options && options.resetProducts && $scope.products) { $scope.products.length = 0 }

      if (window.CatalogFilterCtrl) {
        const filters = window.CatalogFilterCtrl.getSelection();
        if (filters.taxon_ids) $scope.taxonIds = filters.taxon_ids;
      }

      CatalogServiceHelper.keywords = $scope.keywords;

      // Cancel previously open requests
      if ($scope.currentLoadProductsRequest && $scope.currentLoadProductsRequest.resolve) {
        $scope.currentLoadProductsRequest.resolve();
      }

      return $q((resolve, reject) => {
        $scope.loadProducts().then((r) => {
          resolve(r);
        });
      });
    };

    $scope.setListingMode = function(mode, locale) {
      $scope.listMode = mode;
      $scope.update();
    };

    /**
     * Sets sorting and reloads the view
     *
     * @param sortMode
     */
    $scope.sortProductsBy = function(sortMode) {
      if ($scope.sortMode == sortMode) {
        const baseSort = sortMode.replace("_asc", "").replace("_desc", "");
        if (sortMode.indexOf("_asc") > -1) { sortMode = baseSort + "_desc" } else if (sortMode.indexOf("_desc") > -1) { sortMode = baseSort + "_asc" }
      }

      $scope.sortMode = sortMode;

      ahoy.track("catalog-sorting-changed", {mode: sortMode, search: $scope.productSearchTerm, channel: window.xSessionChannel});
      $scope.update({resetPage: true});
    };

    $scope.setProductsLayout = function(layout) {
      if (layout == "default") {
        layout = window.isMobile ? "list" : "full";
      }

      ahoy.track("catalog-layout-changed", {mode: layout, search: $scope.productSearchTerm, channel: window.xSessionChannel});

      CatalogServiceHelper.lastUsedListMode = layout;
      $localStorage.categorylistModeLayout = layout;
      $scope.listMode = layout;
    };

    // Private

    // Initialization

    $scope.$on("$destroy", destructor);
    constructor();
  }
]);
