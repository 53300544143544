import React from 'react'

export class RjsSupportContactFormGeneral extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            customData: {},
            currentUser: UserService.currentUser
        }
        this.$http = props.http;
        this.firstLoad = true;

        this.disabledSubmitButton = this.disabledSubmitButton.bind(this);

        this.onFormSubmitted = props.onFormSubmitted;
    }

    componentDidMount() {
        if (this.state.currentUser?.id) {
            this.state.customData.userName = `${this.state.currentUser.first_name} ${this.state.currentUser.last_name}`;
            this.state.customData.userEmail = this.state.currentUser.email;
            this.state.customData.userPhone = this.state.currentUser.phone_no;
        }

        if (this.props.parentFormType) {
            this.state.customData.parent_form_type = this.props.parentFormType;
        }

        this.state.customData.selectedFiles = [];
    }

    render(props) {
        return <div className='general-form' ref={this.props.forGeneralRef}>
            {this.buildFormContent()}
        </div>
    }

    buildFormInputs() {
        const formInputs = [];
        formInputs.push(<label key='user-name'>
            <span className='text'>Name<span className='red'>*</span></span>
            <input type="text" name='userName' className='general-form-input' onChange={this.handleInputChange.bind(this)}/>
        </label>)
        formInputs.push(<label className='align-right' key='user-email'>
            <span className='text' >Email<span className='red'>*</span></span>
            <input type="email" name='userEmail' className='general-form-input' onChange={this.handleInputChange.bind(this)}/>
        </label>);
        formInputs.push(<label key='user-phone'>
            <span className='text'>Phone<span className='red'>*</span></span>
            <input type="text" name='userPhone' className='general-form-input' onChange={this.handleInputChange.bind(this)}/>
        </label>)

        if(this.props.parentFormType === 'ordering')
            formInputs.push(<label className='align-right' key='user-order-number'>
                <span className='text'>Order Number<span className='red'>*</span></span>
                <input type="text" name='orderNumber' className='general-form-input' value={this.state.selectedOrder} onChange={this.handleInputChange.bind(this)}/>
            </label>)


        return formInputs;
    }

    buildFilledFormInputs() {
        const formInputs = [];
        formInputs.push(<label key='user-name'>
            <span className='text'>{$translate.instant('name')}</span>
            <input type="text" className='general-form-input' defaultValue={this.state.customData.userName} />
        </label>)
        formInputs.push(<label key='user-email' className='align-right'>
            <span className='text' >{$translate.instant('email')}</span>
            <input type="email" className='general-form-input' defaultValue={this.state.customData.userEmail} />
        </label>);
        formInputs.push(<label key='user-phone'>
            <span className='text'>{$translate.instant('phone')}</span>
            <input type="text" className='general-form-input' defaultValue={this.state.customData.userPhone} />
        </label>)

        if(this.props.customData && this.props.customData.selectedOrder != null)
            formInputs.push(<label className='align-right'>
                <span className='text'>{$translate.instant('order_number')}</span>
                <input type="text" className='general-form-input' disabled={true} value={this.props.customData.selectedOrder.number} readOnly={true}/>
            </label>)

        return formInputs;
    }

    buildFormContent() {
        const formInputs = this.state.currentUser == null ? this.buildFormInputs() : this.buildFilledFormInputs();
        return <div>
            <h3 className="title">{$translate.instant('contact.ask_a_question')}</h3>
            <div className='content-general-form'>
                {formInputs}
                <textarea className='general-form-input' name='userMessage' rows="10" placeholder={$translate.instant('contact.desc_your_problem')} onChange={this.handleInputChange.bind(this)}/>
                <button className="btn btn-primary" onClick={this.submitForm.bind(this)} disabled={this.disabledSubmitButton()}>{$translate.instant('submit')}</button>
                <div className="attachments-section">
                    <label className="btn btn-primary attachment-button">
                        <input type="file" className="hidden" accept="image/x-png,image/gif,image/jpeg" onChange={this.onFileChange.bind(this)}/>
                        <i className="fa fa-attach" />
                    </label>
                    {this.printImages()}
                </div>
            </div>
        </div>
    }

    handleInputChange (e) {
        this.props.customData[e.target.name] = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
        Object.assign(this.props.customData, this.state.customData);
        this.setState({customData: this.props.customData});
    }

    onFileChange = event => {
        const file = event.target.files[0];
        if ((file.size/1024/1024) > 2){
            event.target.value = '';
            return Alerts.error($translate.instant('contact.file_too_big', { size: Math.round(file.size/1024/1024 * 100) / 100 }));
        }
        this.state.customData.selectedFiles.push(file);
        this.setState({customData: this.state.customData});
        event.target.value = '';
    };

    printImages() {
        if (this.state.customData.selectedFiles && this.state.customData.selectedFiles.length <= 0) return '';

        const imgs = [];
        for (const fileIndex in this.state.customData.selectedFiles) {
            if (this.state.customData.selectedFiles.hasOwnProperty(fileIndex)) {
                imgs.push(<div className="container-attachment-img" onClick={this.removeAttachedImg.bind(this, fileIndex)}><img src={URL.createObjectURL(this.state.customData.selectedFiles[fileIndex])}  alt={this.state.customData.selectedFiles[fileIndex].name}/></div>);
            }
        }
        return <div className="attachment-preview">{imgs}</div>;
    }

    removeAttachedImg(index, event) {
        this.state.customData.selectedFiles.splice(index, 1);
        this.setState({customData: this.state.customData});
    }

    submitForm() {
        this.state.customData.currentUser = this.state.currentUser;
        this.setState({formSubmitted: true});
        this.$http.post('/api/frontend/support_contact/create_support_ticket.json',
            this.toFormData(this.state.customData), {
                headers: {'Content-Type': undefined},
                transformRequest: angular.identity
        }).then((response) => {
            this.onFormSubmitted(response.data.success);
        }, (error) => {
            console.debug(error);
            this.onFormSubmitted(false);
        });
    }

    disabledSubmitButton() {
        return this.state.formSubmitted || !(this.state.customData && this.state.customData.userName && /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(this.state.customData.userEmail) && this.state.customData.userPhone)
    }

    toFormData(json) {
        const formData = new FormData();
        for (const jsonKey in json) {
            if (json.hasOwnProperty(jsonKey)) {
                if(json[jsonKey] === null || json[jsonKey] === undefined) continue;
                if(jsonKey === 'selectedFiles') {
                    for (const file in json[jsonKey]) {
                        if (json[jsonKey].hasOwnProperty(file)) {
                            formData.append(`selected_file[]`, json[jsonKey][file])
                        }
                    }
                }
                else if (json[jsonKey] instanceof Object) {
                    formData.append(this.camelToSnake(jsonKey), JSON.stringify(json[jsonKey]))
                }
                else {
                    formData.append(this.camelToSnake(jsonKey), json[jsonKey]);
                }
            }
        }
        return formData;
    }

    camelToSnake(string) {
        return string.replace(/[\w]([A-Z])/g, function(m) {
            return m[0] + "_" + m[1];
        }).toLowerCase();
    }
}
