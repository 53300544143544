import React, {useCallback} from "react";
import {trackEvent} from "../../services/tracking-helper";
import FarmyExtrapolate from "../../react/shared/FarmyExtrapolate";

const extrapolations = {
  MOV_regular: window.MinimumOrderValueRegular,
  MOV_reduced: window.MinimumOrderValueReduced
};

export class RjsSupportContactFormHelpInfo extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeQuestion: null
    };
    this.$http = props.http;
    this._isMounted = false;

    this.loadArticles = this.loadArticles.bind(this);
  }

  render(props) {
    return (<div className="helpdesk-faq">
      {this.buildHelpdeskFaq()}
    </div>);
  }

  componentDidMount() {
    this._isMounted = true;
    if (this._isMounted) this.loadArticles();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  buildHelpdeskFaq() {
    if (_.isEmpty(this.state.faqInfo)) return "";

    return this.state.faqInfo.articles.map((questionObj) => {
      return (<div className='question' key={questionObj.id}>
        <h3 onClick={this.showQuestion.bind(this, questionObj.id, questionObj.title)}>
          <FarmyExtrapolate extrapolations={extrapolations}>
            {`<span>${questionObj.title}</span>`}
          </FarmyExtrapolate>
          <i className={"fa fa-" + (this.state.activeQuestion === questionObj.id ? "minus" : "plus")}/></h3>
        <div className={this.state.activeQuestion === questionObj.id ? "" : "hidden" }>
          <FarmyExtrapolate extrapolations={extrapolations}>
            {questionObj.details}
          </FarmyExtrapolate>
        </div>
      </div>);
    });
  }

  showQuestion(id, title) {
    if (this.state.activeQuestion === id) {
      id = null;
    }
    this.setState({activeQuestion: id});
    if (id && title) {
      trackEvent("contactform",
        {
          gtmObject: {
            type: "suggested",
            searchterm: this.props.parentFormType,
            selection: title
          }
        });
    }
  }

  loadArticles() {
    setTimeout(() => {
      this.$http.get(`/api/zendesk/${this.props.parentFormType}/get_articles_by_section.json?locale=${window.I18n.locale}`).then((response) => {
        const faqInfo = response.data;
        // this is just for testing purposes
        if (window.Rails.env !== "production") {
          const testArticle = {
            id: 111,
            title: "Is there a minimal order value ? (this is just a test question for Staging and Development)",
            details: "<p>Minimum order value: CHF {MOV_regular}</p><p>Minimum order value on first order: CHF {MOV_regular} or CHF {MOV_reduced} depending on your delivery address.</p>",
            zendesk_url: "https://helpdesk.farmy.ch/hc/en-ch/articles/16005782062876-Can-I-also-order-by-phone-or-e-mail"
          };
          faqInfo.articles = [...faqInfo.articles, testArticle];
        }
        this.setState({faqInfo}, () => {
          if (this.props.onChildComponentLoaded) this.props.onChildComponentLoaded(true);
        });
      }, (error) => {
        console.debug(error);
      });
    }, 500);
  }
}
