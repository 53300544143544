import * as _ from 'underscore';
import * as moment from 'moment';
import * as sprintf from "sprintf";
import {t} from '../../shared-services/translate';
import {trackEvent} from '../../services/tracking-helper';
import PaginationConfig from '../../react-components/tools/PaginationConfig';
import globalState from "../../shared-services/globalState";

angular.module('app').controller('SupplierPageViewCtrl', ['$scope', '$http', '$q', '$rootScope', '$sce', '$timeout', '$element', 'Alerts', 'blockUI', '$location', 'CatalogServiceHelper', 'TaxonHelper', '$uibModal', '$localStorage', 'UiStateService', 'CartData',
  function ($scope, $http, $q, $rootScope, $sce, $timeout, $element, Alerts, blockUI, $location, CatalogServiceHelper, TaxonHelper, $uibModal, $localStorage, UiStateService, CartData) {
    window.SupplierPageViewCtrl = $scope;
    const {getGlobalState} = globalState();
    const {setPageCode} = getGlobalState("router");

    // Public properties

    window.currentSupplierId = $scope.supplierId = $element.data('supplier-id');
    $scope.videoLayoutReady = false;
    $scope.rootTaxonId = TaxonHelper.CATEGORIES_ROOT_TAXON_ID;
    $scope.sortMode = 'hub_editorial_best';
    $scope.listMode = CatalogServiceHelper.lastUsedListMode = window.isMobile ? 'list' : ($localStorage.categorylistModeLayout || 'smart');
    $scope.UiStateService = UiStateService;
    $scope.isStorefront = window.currentStorefrontSupplier ? true : false;

    /**
     * Multidim register for filter block states
     *
     * @type {{minimizing: {}, minimized: {}}}
     */
    $scope.filterStates = {
      minimizing: {},
      minimized: {}
    };

    CatalogServiceHelper.isSupplierView = true;

    //
    // Private members
    //

    let _destroyed = false;
    var firstLocationLoad = true;
    let videoPlayer = null;
    const {ahoy} = window;

    var unbindCatalogFiltersChanged;

  function constructor() {
    CatalogServiceHelper.currentSupplier = CatalogServiceHelper.currentSupplier || {};
    setPageCode("supplier");
    // TODO: Check url parameters to apply initial taxon filtering

      // Set default taxon
      TaxonHelper.getTaxonInfo($scope.rootTaxonId, {
        description: true,
        include_direct_children: true,
        seo_url: true
      }).then((taxonData) => {
        CatalogServiceHelper.setCurrentTaxon(taxonData);
      });

      unbindCatalogFiltersChanged = $rootScope.$on('catalog:filters:changed', function (event, options) {
        if ($scope.products) $scope.products.length == 0;
        $scope.update();
      });

      TaxonHelper.getTaxonInfo($scope.rootTaxonId);

      $scope.loadSupplier();

      trackEvent("producer");
    }

    function destructor() {
      _destroyed = true;
      window.SupplierPageViewCtrl = null;
      CatalogServiceHelper.isSupplierView = false;
      CatalogServiceHelper.currentSupplier = null;

      if (unbindCatalogFiltersChanged) unbindCatalogFiltersChanged();
    }

    function updateVideoLayout() {
      getVideoPlayer().then(player => {
        player.getVideoWidth().then(videoWidth => {
          player.getVideoHeight().then(videoHeight => {
            var currentFrameWidth = $element.find('.video-frame.vimeo-iframe-wrapper').width();

            let originalRatio = videoWidth / videoHeight;
            let newHeight = currentFrameWidth / originalRatio;
            $element.find('.video-frame.vimeo-iframe-wrapper iframe').css({height: newHeight + 'px'});
            $scope.videoLayoutReady = true;
          })
        })
      })
    }

    function getVideoPlayer() {
      return $q((resolve, reject) => {
        if (videoPlayer) {
          resolve(videoPlayer)
        } else {
          import(/* webpackChunkName: "vimeoPlayer" */ 'ng-frontend/lazy-modules/vimeo-player.js').then((VimeoPlayer) => {
            var iframe = angular.element('.supplier-page-view-ctrl .video-frame.vimeo-iframe-wrapper iframe')[0];
            videoPlayer = new VimeoPlayer(iframe);
            resolve(videoPlayer)
          })
        }
      });
    }

    $scope.setMetaData = function () {
      if ($scope.supplier.meta_title?.length > 0) window.document.title = $scope.supplier.meta_title;
      const canonical = sprintf('%s%s', settingsSiteUrl, $scope.supplier.seo_url);
      const description = $scope.supplier.meta_description?.length > 0 ? $scope.supplier.meta_description : $scope.supplier.short_description;

      $("link[rel='canonical']").attr('href', canonical);
      $("meta[name='description']").attr('content', description);

      if ($scope.supplier.custom_meta_robots) {
        $rootScope.setRobotsMeta($scope.supplier.custom_meta_robots);
      }
    };

    $scope.setNavigationPath = function () {
      if (!$scope._destroyed) {
        if (!window.$locationChanging) {
          $location.changeUrl($scope.supplier.seo_url);
        } else console.warn('ABORTED changeUrl because location change is in progress!');
      }
    };

    //
    // Public members
    //

    /**
     * Pagination info, reference must be presevered for paginator to work.
     *
     * @type {{}}
     */
    $scope.pagination = {
      page: 1,
      per_page: PaginationConfig.itemsPerPage,
    };

    $scope.paginator = new ProductPaginator($scope, $scope.pagination);

    $scope.CatalogServiceHelper = CatalogServiceHelper;

    /**
     * Loads a product collection based on selected filters
     */
    $scope.loadProducts = function () {
      return $q((resolve, reject) => {
        var params = {template: 'product_in_catalog'};
        params.filters = 't';
        params.page = $scope.pagination.page;
        params.per_page = $scope.pagination.per_page;
        params.sort_mode = $scope.sortMode;
        params.locale = $translate.use();
        params.express_delivery = CartData.isExpressDelivery ? 't' : 'f';

        if (!window.CatalogFilterCtrl && _destroyed) {
          reject(false);
          return;
        }

        params.s = $scope.getFilterParameters();

        if (!params.s)
          params.s = {};

        if (!params.s.taxon_ids)
          params.s.taxon_ids = [$scope.rootTaxonId];

        // Load taxon information, if there's a taxon selected
        if (params.s && params.s.taxon_ids && params.s.taxon_ids.length > 0) {
          // Set default taxon
          TaxonHelper.getTaxonInfo(params.s.taxon_ids, {
            description: true,
            include_direct_children: true,
            seo_url: true
          }).then((taxonData) => {
            CatalogServiceHelper.setCurrentTaxon(taxonData);
          });
        }

        $scope.isLoading = true;
        CatalogServiceHelper.setLoading(true);

        $http.get(`/api/products.json?${$.param(params)}`).then((result) => {
          // Abort if the paginator is not available (means the view is destroyed)
          if ($scope.paginator == null) {
            CatalogServiceHelper.setLoading(false);
            return resolve(false);
          }

          if (_destroyed) {
            CatalogServiceHelper.setLoading(false);
            reject(false);
            return;
          }

          $scope.paginator.updatePaginationFromResponse(result.data);

          if ($scope.pagination.current_page == 1 || $scope.products == null || $scope.products.length == 0) {
            $scope.products = result.data.products
          } else { // Non-first page will merge results
            _.each(result.data.products, p => _.findIndex($scope.products, ep => ep.id == p.id) == -1 && $scope.products.push(p))
          }

          // Inject and update filters into the neighboring filters controller, if present
          if (result.data.filters) {
            if (window.CatalogFilterCtrl) {
              $timeout(() => {
                window.CatalogFilterCtrl.updateFilters(result.data.filters);
                $rootScope.$broadcast('catalog:filters:loaded', {
                  filters: result.data.filters,
                  sender: $scope,
                  viewmode: 'supplier'
                });
                CatalogServiceHelper.onFilterSelectionChange();
                $scope.isLoading = false;
                CatalogServiceHelper.setLoading(false);
              });
            } else {
              $scope.filters = result.data.filters;
              $rootScope.$broadcast('catalog:filters:loaded', {
                filters: result.data.filters,
                sender: $scope,
                viewmode: 'supplier'
              });
              $scope.isLoading = false;
              CatalogServiceHelper.setLoading(false);
            }
          }

          resolve($scope.products);
        })
      });
    };

    $scope.update = function (options) {
      if (options && options.resetPage)
        $scope.pagination.page = $scope.pagination.current_page = 1;

      if (options && options.resetProducts && $scope.products)
        $scope.products.length = 0;

      if (window.CatalogFilterCtrl) {
        var filters = window.CatalogFilterCtrl.getSelection();
        if (filters.taxon_ids) $scope.taxonIds = filters.taxon_ids;

        // Set url parameters to match the filter selection, so that the URL is shareable
        if (!window.$locationChanging) {
          $location.changeSearch(CatalogServiceHelper.serializeFilterSelectionToUrlParams(filters));
        }
      }

      return $q((resolve, reject) => {
        $scope.loadProducts().then((r) => {
          // TODO: Category browse management must be different
          // TaxonHelper.getTaxonInfo($scope.taxonIds[0], { description: true, include_direct_children: true, seo_url: true }).then((taxonData) => {
          //   $scope.taxonData = $scope.currentTaxon = taxonData;
          //   CatalogServiceHelper.setCurrentTaxon($scope.taxonData);
          //   $scope.currentTaxonId = taxonData.id;
          //   setMetaAndOgDataForTaxon(taxonData);
          //
          //   if (!firstLocationLoad && !_destroyed) {
          //     if (!window.$locationChanging) $location.changeUrl('/' + taxonData.permalink, { prependLocale: true });
          //     else console.warn("ABORTED changeUrl because location change is in progress!");
          //   }
          // })

          if (!firstLocationLoad) {
            setTimeout(() => {
              if ($scope.pagination.page == 1) CatalogServiceHelper.scrollToCatalogHead();
            }, 200);

            // Change url to fit filters
            if (_.isEmpty($scope.getFilterParameters())) {
              $location.changeSearch(angular.extend(angular.copy($location.changeUrl), {s: $scope.getFilterParameters()}))
            }
          }

          firstLocationLoad = false;

          resolve(r);
        })
      });
    };

    $scope.loadSupplier = function () {
      return $http.get(`/api/frontend/suppliers/${$scope.supplierId}/multimedia_contents.json`, {
        params: {
          locale: I18n.locale
        }
      }).then((result) => {
        $scope.supplierMultimedia = result.data.multimedia;
        $scope.supplier = result.data.supplier;

        $scope.setNavigationPath();
        $scope.setMetaData();

        // Update shared currentSupplier
        angular.extend(CatalogServiceHelper.currentSupplier, $scope.supplier);

        $scope.update();

        if ($scope.supplierMultimedia.video) {
          $scope.supplierMultimedia.video = $sce.trustAsHtml($scope.supplierMultimedia.video);

          $timeout(() => updateVideoLayout(), 50);
        }

        if ($scope.supplierMultimedia.images && $scope.supplierMultimedia.images.length > 0) {
          $scope.setCurrentImage(0);
        }
      }, e => {
        Alerts.error(errorMessage(e));
      })
    };

    $scope.setCurrentImage = function (num) {
      $scope.currentImageNum = num;

      if ($scope.currentImageNum >= 0) {
        $('.photo-gallery img.main-image').attr('src', $scope.supplierMultimedia.images[num].large);
        $scope.currentImage = $scope.supplierMultimedia.images[num];
      }
    };

    $scope.loadLocalePreferences = function () {

    };

    $scope.hideVideo = function () {
      $scope.videoHidden = true;

      // TODO: Save to local storage
    };

    /**
     * Sets sorting and reloads the view
     *
     * @param sortMode
     */
    $scope.sortProductsBy = function (sortMode) {
      if ($scope.sortMode == sortMode) {
        var baseSort = sortMode.replace('_asc', '').replace('_desc', '');
        if (sortMode.indexOf('_asc') > -1)
          sortMode = baseSort + '_desc';
        else if (sortMode.indexOf('_desc') > -1)
          sortMode = baseSort + '_asc';
      }

      $scope.sortMode = sortMode;

      $scope.update();
      ahoy.track("catalog-sorting-changed", {
        mode: sortMode,
        supplier_id: CatalogServiceHelper.currentSupplier.id,
        channel: window.xSessionChannel
      });
    };

    $scope.setProductsLayout = function (layout) {
      let newLayout = layout || (
        CatalogServiceHelper.lastUsedListMode = window.isMobile
          ? "mobile_cards"
          : ($localStorage.categorylistModeLayout || "full"));

      if (newLayout === "default") {
        newLayout = window.isMobile ? "list" : "full";
      }

      ahoy.track("catalog-layout-changed", {
        mode: newLayout,
        supplier_id: CatalogServiceHelper.currentSupplier.id,
        channel: window.xSessionChannel
      });

      CatalogServiceHelper.lastUsedListMode = newLayout;
      $localStorage.categorylistModeLayout = newLayout;
      $scope.listMode = newLayout;
    };

    $scope.getFilterParameters = function () {
      var params = {};

      if (window.CatalogFilterCtrl)
        params = angular.extend(params, window.CatalogFilterCtrl.getSelection());

      params.hub_ids = Hubs.currentHubId;

      if (window.currentStorefrontSupplier && !['bio_suisse', 'farmybusiness'].includes(window.currentStorefront)) params.sourcing_supplier_id = $scope.supplier.id; // force supplier scope
      else params.supplier_id = $scope.supplier.id; // force supplier scope

      return params;
    };

    $scope.openGalleryModal = function () {
      if (browserMobileDevice || $('.photo-gallery .main-image').parents('.container-fluidish').width() == $('.photo-gallery .main-image').width())
        return;

      var galleryModal = $uibModal.open({
        animation: true,
        templateUrl: '/ng/templates/suppliers/supplier_gallery_modal.html',
        controller: 'supplierGalleryModalCtrl',
        size: 'lg',
        windowClass: 'supplier-gallery-modal',
        resolve: {
          currentIndex: $scope.currentImageNum,
          images: $scope.supplierMultimedia
        }
      });

      galleryModal.result.then(() => {

      })
    };

    $scope.trackGtmOutboundLinkEvent = function () {
      const gtmObject = {
        type: "supplier",
        detail: $scope.supplier?.name
      };
      trackEvent("outboundLink", {gtmObject});
    };

    // Initialize

    $scope.$on('$destroy', destructor);
    constructor()
  }]);
