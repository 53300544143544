'use strict';

import {trackPageView} from '../../services/tracking-helper';
import globalState from "../../shared-services/globalState";

angular.module('app').controller('RegistrationViewCtrl', ['$scope', '$rootScope', '$timeout', '$window', '$routeParams', '$location', 'CartData', function ($scope, $rootScope, $timeout, $window, $routeParams, $location, CartData) {
  window.RegistrationViewCtrl = $scope;
  window.$routeParams = $routeParams;
  const {getGlobalState} = globalState();
  const {setPageCode} = getGlobalState("router");

  $scope.enableB2bSignup = window.enableB2bSignup;
  $scope.isB2b = false;
  $scope.backToUrl = $location.search().back_to_url ? $location.search().back_to_url : null;

  var unOnRegistration;

  function constructor() {
    unOnRegistration = $rootScope.$on('user:authenticated', () => { $timeout(() => { onUserRegistered() }, 10) });

    $scope.proceedToCheckout = $location.search().checkout;
    $scope.showLogin = $location.search().login;
    setPageCode("registration");

    $scope.$watch('isB2b', (newValue) => {
      if (window.UserRegistrationFormCtrl)
        window.UserRegistrationFormCtrl.isB2b = newValue;
    });

    trackPageView(`signup_page${$location.search()?.checkout === 't' ? '_checkout' : ''}`);

    $window.scrollTo(0, 0);
  }

  function destructor() {
    window.RegistrationViewCtrl = null;
    if (unOnRegistration) unOnRegistration();
  }

  function onUserRegistered() {
    // For mobile and no-sidebar workflow, users get sent to the checkout after registration
    let checkoutPath = (window.isMobile || !NgFrontendAppCtrl.enableCartSidebar) ? '/cashier' : '/cart';

    if ($scope.proceedToCheckout) {
      Alerts.success($translate.instant('messages.logged_in_succesfully'))
      $location.path(`${NgFrontendAppCtrl.locationLocalePrefix}${checkoutPath}`).search({});
    } else {
      if ($scope.backToUrl)
        $location.url($scope.backToUrl);
      else
        $location.path('/');
    }
  }

  $scope.CartData = CartData;

  $scope.loginUser = {
    email: undefined,
    password: undefined
  };

  $scope.onLoginFormSubmit = function() {
    $scope.authenticationInProgress = true;

    UserService.authenticate($scope.loginUser.email, $scope.loginUser.password).then((user) => {
      $scope.authenticationInProgress = false;
      $timeout(() => onUserRegistered(), 10);
    }, error => {
      $scope.authenticationInProgress = false;
    })
  };

  $scope.onEnterKeyPress = function(e) {
    if (e.keyCode === 13 && $scope.tosConfirmed) UserRegistrationFormCtrl.onSubmitClicked()
  };

  $scope.dismiss = function() {
    $window.history.back();
  }

  $scope.$on('$destroy', destructor);
  constructor();
}

]);